var global = require('./global');
const FULL_ANGLE = 2 * Math.PI;

const drawRoundObject = (position, radius, graph) => {
    graph.beginPath();
    graph.arc(position.x, position.y, radius, 0, FULL_ANGLE);
    graph.closePath();
    graph.fill();
    graph.stroke();
}

const darkenColor = (color, amount) => {
    // Parse the color and decrease brightness to darken it
    let colorComponents = color.match(/\d+/g);
    if (!colorComponents) return color;
    const [r, g, b] = colorComponents.map(Number);
    return `rgb(${Math.max(0, r - amount)}, ${Math.max(0, g - amount)}, ${Math.max(0, b - amount)})`;
};

const drawPolygonalOutline = (position, radius, waveAmplitude, waveFrequency, graph) => {
    const points = 20; // Use 10 points for a polygonal appearance

    graph.beginPath();

    for (let i = 0; i <= points; i++) {
        const theta = (i / points) * FULL_ANGLE;

        // Apply a smooth sine wave offset for a subtle "breathing" effect
        const offset = Math.sin(Date.now() * waveFrequency + theta) * waveAmplitude;
        const dynamicRadius = radius + offset;

        const pointX = position.x + dynamicRadius * Math.cos(theta);
        const pointY = position.y + dynamicRadius * Math.sin(theta);

        if (i === 0) {
            graph.moveTo(pointX, pointY);
        } else {
            graph.lineTo(pointX, pointY);
        }
    }

    graph.closePath();
    graph.stroke();
    graph.fill(); // Fill to avoid gaps
};

const isTouching = (cell1, cell2) => {
    const dx = cell1.x - cell2.x;
    const dy = cell1.y - cell2.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance < cell1.radius + cell2.radius;
};
const drawFood = (position, food, graph) => {
    graph.fillStyle = food.hue;
    graph.strokeStyle = "white";
    graph.lineWidth = 2;               // Set line width to make the outline visible
    drawRoundObject(position, food.radius, graph);
};

const drawVirus = (position, virus, graph) => {
    graph.strokeStyle = virus.stroke;
    graph.fillStyle = virus.fill;
    graph.lineWidth = virus.strokeWidth;
    let theta = 0;
    let sides = 20;

    graph.beginPath();
    for (let theta = 0; theta < FULL_ANGLE; theta += FULL_ANGLE / sides) {
        let point = circlePoint(position, virus.radius, theta);
        graph.lineTo(point.x, point.y);
    }
    graph.closePath();
    graph.stroke();
    graph.fill();
};

const drawFireFood = (position, mass, playerConfig, graph) => {
    graph.strokeStyle = 'hsl(' + mass.hue + ', 100%, 45%)';
    graph.fillStyle = 'hsl(' + mass.hue + ', 100%, 50%)';
    graph.lineWidth = playerConfig.border + 2;
    drawRoundObject(position, mass.radius - 1, graph);
};

const valueInRange = (min, max, value) => Math.min(max, Math.max(min, value))

const circlePoint = (origo, radius, theta) => ({
    x: origo.x + radius * Math.cos(theta),
    y: origo.y + radius * Math.sin(theta)
});

const cellTouchingBorders = (cell, borders) =>
    cell.x - cell.radius <= borders.left ||
    cell.x + cell.radius >= borders.right ||
    cell.y - cell.radius <= borders.top ||
    cell.y + cell.radius >= borders.bottom

const regulatePoint = (point, borders) => ({
    x: valueInRange(borders.left, borders.right, point.x),
    y: valueInRange(borders.top, borders.bottom, point.y)
});

const drawCellWithLines = (cell, borders, graph) => {
    let pointCount = 30 + ~~(cell.mass / 5);
    let points = [];
    for (let theta = 0; theta < FULL_ANGLE; theta += FULL_ANGLE / pointCount) {
        let point = circlePoint(cell, cell.radius, theta);
        points.push(regulatePoint(point, borders));
    }
    graph.beginPath();
    graph.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
        graph.lineTo(points[i].x, points[i].y);
    }
    graph.closePath();
    graph.fill();
    graph.stroke();
}

const drawCells = (cells, playerConfig, toggleMassState, borders, graph) => {
    for (let cell of cells) {
        // Define outline color as a slightly darker version of the cell's color
        graph.strokeStyle = "white";
        graph.lineWidth = 4;
        graph.fillStyle = cell.color;

        // Draw the water-like, fluid outline
        drawPolygonalOutline(cell, cell.radius + 3, 1.2, 0.005, graph);

        // Draw the cell body with a slight overlap to avoid gaps
        graph.fillStyle = cell.color;
        graph.strokeStyle = cell.borderColor;
        graph.lineWidth = 1;
        drawRoundObject(cell, cell.radius - 0.5, graph);

        // Draw the name of the player
        let fontSize = Math.max(cell.radius / 3, 18);
        graph.lineWidth = playerConfig.textBorderSize;
        graph.fillStyle = playerConfig.textColor;
        graph.strokeStyle = playerConfig.textBorder;
        graph.miterLimit = 1;
        graph.lineJoin = 'round';
        graph.textAlign = 'center';
        graph.textBaseline = 'middle';
        graph.font = 'bold ' + fontSize + 'px Puffin Display Soft';
        graph.strokeText(cell.name, cell.x, cell.y - fontSize / 2);
        graph.fillText(cell.name, cell.x, cell.y - fontSize / 2);

        // Draw the mass (if enabled)
            // Define smaller font size for mass and value display
        const massFontSize = Math.max(fontSize / 1.5, 12);
        graph.font = 'bold ' + massFontSize + 'px Puffin Display Soft';

        // Offset values to separate each line
        const nameOffset = fontSize * 0.6;
        const worthSOLYOffset = fontSize * 0.2;
        const worthUSDYOffset = fontSize * 1.0;

        // Calculate worth in SOL and USD
        const worthSOL = typeof cell.worth === 'number' ? (cell.worth).toFixed(3) : '0.00';
        const worthUSD = typeof cell.worth === 'number' ? (cell.worth * global.solPrice).toFixed(3) : '0.00';

        // Draw the SOL worth below the name
        graph.strokeText(`${worthSOL} SOL`, cell.x, cell.y + nameOffset + worthSOLYOffset);
        graph.fillText(`${worthSOL} SOL`, cell.x, cell.y + nameOffset + worthSOLYOffset);

        // Draw the USD worth below the SOL worth
        graph.strokeText(`$${worthUSD}`, cell.x, cell.y + nameOffset + worthUSDYOffset);
        graph.fillText(`$${worthUSD}`, cell.x, cell.y + nameOffset + worthUSDYOffset);
    }
};

const drawGrid = (global, player, screen, graph) => {
    graph.lineWidth = 1;
    graph.strokeStyle = global.lineColor;
    graph.globalAlpha = 0.15;
    graph.beginPath();

    for (let x = -player.x; x < screen.width; x += screen.height / 18) {
        graph.moveTo(x, 0);
        graph.lineTo(x, screen.height);
    }

    for (let y = -player.y; y < screen.height; y += screen.height / 18) {
        graph.moveTo(0, y);
        graph.lineTo(screen.width, y);
    }

    graph.stroke();
    graph.globalAlpha = 1;
};

const drawBorder = (borders, graph) => {
    graph.lineWidth = 1;
    graph.strokeStyle = '#ffffff'
    graph.beginPath()
    graph.moveTo(borders.left, borders.top);
    graph.lineTo(borders.right, borders.top);
    graph.lineTo(borders.right, borders.bottom);
    graph.lineTo(borders.left, borders.bottom);
    graph.closePath()
    graph.stroke();
};

const drawErrorMessage = (message, graph, screen) => {
    graph.fillStyle = '#333333';
    graph.fillRect(0, 0, screen.width, screen.height);
    graph.textAlign = 'center';
    graph.fillStyle = '#FFFFFF';
    graph.font = 'bold 30px sans-serif';
    graph.fillText(message, screen.width / 2, screen.height / 2);
}

module.exports = {
    drawFood,
    drawVirus,
    drawFireFood,
    drawCells,
    drawErrorMessage,
    drawGrid,
    drawBorder
};
