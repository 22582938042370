import io from 'socket.io-client';
import render from './render';
import ChatClient from './chat-client';
import { Buffer } from 'buffer';
import ParticleSystem from './particles'

var environment = process.env.NODE_ENV;
var serverUrl = process.env.REACT_APP_SERVER_URL;
var selectedGame = "free"
var selectedGameCost = 0

window.Buffer = Buffer;
import '../css/main.css'

var Canvas = require('./canvas');
var global = require('./global');
let walletAddress = null;

var playerNameInput = document.getElementById('playerNameInput');

var socket;
const LAMPORTS_PER_SOL = 1000000000;
const programId = new solanaWeb3.PublicKey("arJQ6hEEtcN6XrtTv5631ciUoCWrSF8CKk9H5A7i1NC");
const connection = new solanaWeb3.Connection('https://devnet.helius-rpc.com/?api-key=948580a5-52ff-4cb3-97a4-e1704d67215f', 'confirmed');
const particleSystem = new ParticleSystem();

const devKey = [73,121,202,154,133,195,138,81,14,139,168,41,223,37,253,32,112,40,26,218,12,129,79,149,76,41,42,3,104,51,125,198,230,18,215,171,188,14,165,168,46,207,248,149,62,147,0,35,205,73,221,56,74,209,123,123,91,183,64,89,164,248,60,191]
const topUpWallet = solanaWeb3.Keypair.fromSecretKey(Buffer.from(devKey))


const LOBBY_FREE_FOOD_PDA = "4DjLMe4JHkt41YGDTD9AsqtRYRdLAEsiDpT2QNXmhB2r"

var debug = function (args) {
    if (console && console.log) {
        console.log(args);
    }
};

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    global.mobile = true;
}

// $("#login").click(async function (event) {
//   try {
//         document.getElementById('startButton').textContent = "Play";
//   } catch (error) {
//     console.error(error.message);
//   }
// });

setInterval(fetchTokenPrice('So11111111111111111111111111111111111111112').then((price) => {
    global.solPrice = price
}), 10000);

function displayTokenBalances(tokenList) {
        const dropdown = document.getElementById('tokenSelectDropdown');
        dropdown.innerHTML = ''; // Clear previous options

        tokenList.forEach(token => {
            const option = document.createElement('option');
            option.value = token.name; // Assuming "name" is the unique identifier
            option.textContent = token.name;
            dropdown.appendChild(option);
        });

        // Optional: Listen for selection change
        dropdown.addEventListener('change', function() {
            global.mint = dropdown.value; // Example of setting a global variable
        });
    }

async function startGame(type) {
    if (!global.socket) {
        console.error("socket not configured")
        return
    }
    let name = playerNameInput.value.replace(/(<([^>]+)>)/ig, '').substring(0, 25);
    global.playerName = name;
    global.playerType = type;

    global.screen.width = window.innerWidth;
    global.screen.height = window.innerHeight;

    // document.getElementById('startMenuWrapper').style.maxHeight = '0px';
    document.getElementById('loading-screen').style.visibility = 'visible'
    document.getElementById("ui-container").style.display = "none";
    document.getElementById('gameAreaWrapper').style.opacity = 1;
    document.getElementById('startGameWalletModal').style.display = 'none';

    if (!global.animLoopHandle)
        animloop();
    if (type == "player") {
        global.socket.emit('respawn', { pubKey: global.pubKey });
    }

    window.chat.socket = global.socket;
    window.chat.registerFunctions();
    window.canvas.socket = global.socket;
}

function stopGame() {
    document.getElementById('particleCanvas').style.visibility = 'visible'
    particleSystem.start();
    global.gameStart = false;
    document.getElementById('gameAreaWrapper').style.opacity = 0;
    if (global.animLoopHandle) {
        window.cancelAnimationFrame(global.animLoopHandle);
        global.animLoopHandle = undefined;
    }
    document.getElementById("ui-container").style.display = "flex";

    // Reset game variables
    global.playerName = null;
    global.playerType = null;

    // Hide game area and show start menu
    document.getElementById('gameAreaWrapper').style.opacity = 0;
    // document.getElementById('startMenuWrapper').style.maxHeight = '100%';

    // Stop the animation loop if it’s running
    if (global.animLoopHandle) {
        cancelAnimationFrame(global.animLoopHandle);
        global.animLoopHandle = null;
    }

    // Disconnect socket connection if it exists
    if (global.socket) {
        // global.socket.emit('disconnect'); // Notify server if necessary
        global.socket.close();
        global.socket.disconnect();       // Close the connection
        global.socket = null;
    }

    // Clean up other elements associated with the game
    if (window.chat) {
        window.chat.socket = null;
        // window.chat.unregisterFunctions(); // Unregister chat functions if you have this method
    }

    if (window.canvas) {
        window.canvas.socket = null;
    }

    document.removeEventListener('keydown', handleEscapeKey);
}

function handleEscapeKey(event) {
    if (event.keyCode === global.KEY_ESC) {
        stopGame();
    }
}
// Checks if the nick chosen contains valid alphanumeric characters (and underscores).
function validNick() {
    var regex = /^\w+$/;
    return regex.test(playerNameInput.value) && playerNameInput.value.trim().length > 0;
}



window.onload = function () {
    let privateKeyArray = JSON.parse(localStorage.getItem('solanaPrivateKey'));
    let publicKey;

    if (privateKeyArray) {
        // If it exists, load the keypair from local storage
        const privateKey = Uint8Array.from(privateKeyArray);
        const keypair = solanaWeb3.Keypair.fromSecretKey(privateKey);
        publicKey = keypair.publicKey.toString();
    } else {
        // If not, generate a new keypair and store the private key
        publicKey = generateNewKeypair();

    }

    document.getElementById("walletAddress").textContent = publicKey.substring(0, 5) + "..." + publicKey.substring(publicKey.length - 5)
    global.pubKey = publicKey;
    setupOnLoad();
};


const fetchAndSetBalance =  () => {
    fetchSolBalanceWithInfo(global.pubKey).then((solBalance) => {
        // if (!solBalance) { return }
        document.getElementById("solBalance").textContent = `$${solBalance?.valueUSD?.toFixed(4)}(${solBalance.balance?.toFixed(4)})`;
        global.solBalance = solBalance.balance;
    })

    getGamePdaAccount(global.pubKey).then((pda) => {
        global.pdaAccount = pda
        fetchSolBalanceWithInfo(pda).then((solBalance) => {
            // if (!solBalance) { return }
            document.getElementById("solRewardsBalance").textContent = `$${solBalance?.valueUSD?.toFixed(4)}(${solBalance.balance?.toFixed(4)})`;
            global.solPdaBalance = solBalance.balance;
        })
    })
}
function setupOnLoad() {
    const qrcode = new QRCode(document.getElementById("qrcode"), {
        text: global.pubKey,
        width: 128,
        height: 128
    });

    fetchAndSetBalance()
    // document.getElementById('diedMenuWrapper').style.maxHeight = '0px';
    // document.getElementById('diedMenuWrapper').style.opacity = 0;

    // const tokens = [
    //     { name: "WIF", img: "linkToPictureA" },
    //     { name: "GIGA", img: "linkToPictureB" },
    //     { name: "Moo Deng", img: "linkToPictureC" },
    //     { name: "Aura", img: "linkToPictureD" },
    //     { name: "$YeahImOut", img: "linkToPictureD" },
    //     { name: "SOL", img: "linkToPictureD" },
    //     { name: "USDC", img: "linkToPictureD" }
    // ];
    //
    // displayTokenBalances(tokens)

    var joinFree = document.getElementById('joinFree')

    var joinOne = document.getElementById('joinOne')
    var joinFive = document.getElementById('joinFive')

    var playGameButton = document.getElementById('startGameButton')
        // btnPlayAgain = document.getElementById('retryButton'),
    var btnS = document.getElementById('spectateButton')
        // btnLogout = document.getElementById('logout'),
    var nickErrorText = document.getElementById('input-error');

    const copyWallet = document.getElementById("copyAddressButton")
    const copyWalletClose = document.getElementById("walletCloseButton")
    const topUpWalletButton = document.getElementById("walletTopUpButton")


    const startGameClose = document.getElementById("startGameCloseButton")

    copyWalletClose.onclick = async function() {
        document.getElementById("walletModal").style.display = "none";
    }

    startGameClose.onclick = async function() {
        document.getElementById("startGameWalletModal").style.display = "none";
    }

    copyWallet.onclick = async function() {
        navigator.clipboard.writeText(global.pubKey)
    };

    btnS.onclick = function () {
        startGame('spectator');
    };

    topUpWalletButton.onclick = async function() {
        await topUpGameWallet()
    }

    // btnPlayAgain.onclick = function () {
    //     startGame('player');
    // };


    playGameButton.onclick = async function() {
        try {
            if (validNick()) {
                nickErrorText.style.opacity = 0;
                nickErrorText.style.display = 'none';
                startGame('player');
            } else {
                nickErrorText.style.opacity = 1;
                nickErrorText.style.display = 'flex';
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    joinFree.onclick = async function() {
        selectedGame = "FREE_GAME"
        selectedGameCost = 0
        console.log(`Connecting to server ${process.env.FREE_GAME}`)
        await connectToServer(process.env.FREE_GAME)
        document.getElementById("startGameWalletModal").style.display = "flex";
    };

    joinOne.onclick = async function() {
        selectedGame = "ONE_DOLLAR_GAME"
        selectedGameCost = Number(process.env.ONE_DOLLAR_GAME_COST)
        console.log("sleected game csot", selectedGameCost)
        console.log("sleected game csot type", typeof(selectedGameCost))
        console.log(`Connecting to server ${process.env.ONE_DOLLAR_GAME}`)
        await connectToServer(process.env.ONE_DOLLAR_GAME)
        if (!global.solPdaBalance || global.solPdaBalance < selectedGameCost) {
            document.getElementById("walletModal").style.display = "flex";
            return
        }
        document.getElementById("startGameWalletModal").style.display = "flex";
    };

    joinFive.onclick = async function() {
        selectedGame = "FIVE_DOLLAR_GAME"
        console.log(process.env.FIVE_DOLLAR_GAME_COST, "GAMEEEEEEEEEEEEEEEEEEEEEEEEEEee")
        selectedGameCost = Number(process.env.FIVE_DOLLAR_GAME_COST)
        console.log("sleected game csot", selectedGameCost)
        console.log("sleected game csot type", typeof(selectedGameCost))
        console.log(" i am here.")
        console.log(`Connecting to server ${process.env.FIVE_DOLLAR_GAME}`)
        if(!process.env.FIVE_DOLLAR_GAME) {
           console.error("no server found!")
        }
        await connectToServer(process.env.FIVE_DOLLAR_GAME)
        if (!global.solPdaBalance || global.solPdaBalance < selectedGameCost) {
            document.getElementById("walletModal").style.display = "flex";
            return
        }
        document.getElementById("startGameWalletModal").style.display = "flex";
    };

    playerNameInput.addEventListener('keypress', function (e) {
        var key = e.which || e.keyCode;

        if (key === global.KEY_ENTER) {
            if (validNick()) {
                nickErrorText.style.opacity = 0;
                startGame('player');
            } else {
                nickErrorText.style.opacity = 1;
            }
        }
    });

}
// TODO: Break out into GameControls.
const connectToServer = async (slug) => {
    console.log("Trying to connect to:", slug);

    if (global.socket && global.socket.connected) {
    console.log("Server already connected")
    return;
    }

    if (environment === 'production') {
        console.log("Connecting to Production Server")
        socket = io(serverUrl, {
            path: `/${slug}/socket.io`,
            query: { type: "player" }
        });
    } else {
        console.log("Connecting to Development Server")
        socket = io(serverUrl, {
            path: `/${slug}/socket.io`,
            query: { type: "player" }
        });
    }

    global.socket = socket;
    setupSocket(global.socket);
};

const topUpGameWallet = async () => {
    console.log(`Topping up user wallet: ${global.pubKey}`)
    if(!global.pubKey) {
        console.error("No public key found")
        return
    }
    console.log("game cost from topllluUP for game", `${selectedGame}_COST`)
    if(global.solBalance <= selectedGameCost) {
        console.log(" we are here")
       await sendDevSolToWallet(global.pubKey, selectedGameCost+.1)
    }
    await ensurePDAInitialized(global.pubKey)
    const pdaAccount = await getGamePdaAccount(global.pubKey)
    console.log(pdaAccount, "this is the pda account")
    const { success, amountNeeded } = await ensureEnoughBalance(pdaAccount, selectedGameCost)
    if (success == 0) {
        global.socket.emit('tryDeposit', { pubKey: global.pubKey, amountNeeded });
    }
    fetchAndSetBalance()
}

var playerConfig = {
    border: 6,
    textColor: '#FFFFFF',
    textBorder: '#000000',
    textBorderSize: 3,
    defaultSize: 30
};

var player = {
    id: -1,
    x: global.screen.width / 2,
    y: global.screen.height / 2,
    screenWidth: global.screen.width,
    screenHeight: global.screen.height,
    target: { x: global.screen.width / 2, y: global.screen.height / 2 }
};
global.player = player;

var foods = [];
var priceFoods = [];
var viruses = [];
var fireFood = [];
var users = [];
var leaderboard = [];
var target = { x: player.x, y: player.y };
global.target = target;

window.canvas = new Canvas();
window.chat = new ChatClient();

var c = window.canvas.cv;
var graph = c.getContext('2d');

$("#feed").click(function () {
    socket.emit('1');
    window.canvas.reenviar = false;
});

$("#split").click(function () {
    socket.emit('2');
    window.canvas.reenviar = false;
});

function handleDisconnect() {
    socket.close();
    if (!global.kicked) { // We have a more specific error message
        render.drawErrorMessage('Disconnected!', graph, global.screen);
    }
}

// socket stuff.
function setupSocket(socket) {
    // Handle ping.
    socket.on('pongcheck', function () {
        var latency = Date.now() - global.startPingTime;
        debug('Latency: ' + latency + 'ms');
        window.chat.addSystemLine('Ping: ' + latency + 'ms');
    });

    // Handle error.
    socket.on('connect_error', handleDisconnect);
    socket.on('disconnect', handleDisconnect);

    // Handle connection.
    socket.on('welcome', function (playerSettings, gameSizes) {
        // document.getElementById('diedMenuWrapper').style.maxHeight = '0%';
        // document.getElementById('diedMenuWrapper').style.opacity = 0;
        document.addEventListener('keydown', handleEscapeKey)
        document.getElementById('loading-screen').style.visibility = 'hidden'
        document.getElementById('particleCanvas').style.visibility = 'hidden'
        particleSystem.stop()
        player = playerSettings;
        player.name = global.playerName;
        player.mint = global.mint;
        player.pubKey = global.pubKey;
        player.tokenAmount = global.tokenAmount;
        player.screenWidth = global.screen.width;
        player.screenHeight = global.screen.height;
        player.target = window.canvas.target;
        global.player = player;
        window.chat.player = player;
        socket.emit('gotit', player);
        global.gameStart = true;
        window.chat.addSystemLine('Connected to the game!');
        window.chat.addSystemLine('Type <b>-help</b> for a list of commands.');
        if (global.mobile) {
            document.getElementById('gameAreaWrapper').removeChild(document.getElementById('chatbox'));
        }
        c.focus();
        global.game.width = gameSizes.width;
        global.game.height = gameSizes.height;
        resize();
    });

    socket.on('playerDied', (data) => {
        const player = data.playerEatenName;

        //const killer = isUnnamedCell(data.playerWhoAtePlayerName) ? 'An unnamed cell' : data.playerWhoAtePlayerName;

        //window.chat.addSystemLine('{GAME} - <b>' + (player) + '</b> was eaten by <b>' + (killer) + '</b>');
        window.chat.addSystemLine('{GAME} - <b>' + (player) + '</b> was eaten');
    });

    socket.on('playerDisconnect', (data) => {
        window.chat.addSystemLine('{GAME} - <b>' + (data.name) + '</b> disconnected.');
    });

    socket.on('playerJoin', (data) => {
        window.chat.addSystemLine('{GAME} - <b>' + (data.name) + '</b> joined.');
    });

socket.on('leaderboard', (data) => {
    leaderboard = data.leaderboard;
    let status = '<span class="title">Leaderboard</span>';
    for (let i = 0; i < leaderboard.length; i++) {
        status += '<br />';
        let playerName = leaderboard[i].name;
        let playerWorth = leaderboard[i].worth;

        // Ensure `playerWorth` is a number and set a default if not
        let worthDisplay = typeof playerWorth === 'number' && !isNaN(playerWorth)
            ? playerWorth.toFixed(4)
            : '0.00';

        if (leaderboard[i].id === player.id) {
            if (playerName.length !== 0)
                status += `<span class="me">${i + 1}. ${playerName} (${worthDisplay} SOL)</span>`;
        } else {
            if (playerName.length !== 0)
                status += `${i + 1}. ${playerName} (${worthDisplay} SOL)`;
        }
    }
    document.getElementById('status').innerHTML = status;
});

    socket.on('stats', (data) => {
        var totalTokens = data.totalTokens;
        var totalTokensOnMap = data.totalTokensOnMap;
        var status = '<span class="title">Stats</span>';

        // Update the stats display
        document.getElementById('game-stats').innerHTML = status;
    });

    socket.on('serverMSG', function(data) {
        window.chat.addSystemLine(data);
    });

    socket.on('serverMSG', function (data) {
        window.chat.addSystemLine(data);
    });

    // Chat.
    socket.on('serverSendPlayerChat', function (data) {
        window.chat.addChatLine(data.sender, data.message, false);
    });

    // Handle movement.
    socket.on('serverTellPlayerMove', function (playerData, userData, foodsList, priceFoodList, massList, virusList) {
        if (global.playerType == 'player') {
            player.x = playerData.x;
            player.y = playerData.y;
            player.hue = playerData.hue;
            player.massTotal = playerData.massTotal;
            player.worthTotal = playerData.worthTotal;
            player.cells = playerData.cells;
        }
        users = userData;
        foods = foodsList;
        priceFoods = priceFoodList;
        viruses = virusList;
        fireFood = massList;
    });

    socket.on('depositSOL', function(data) {
        signAndSendTransaction(data.tx).then((val) => {
            console.log(val)
        });
    })

    socket.on('eat_balance_change', function(data) {
        const notificationBox = document.getElementById('notification-box');
        const notificationText = document.getElementById('notification-text');
        const solscanLink = document.getElementById('solscan-link');

        // Set notification text and Solscan link
        notificationText.textContent = `You received ${data?.worth?.toFixed(4) || 0}`;
        solscanLink.href = `https://solscan.io/tx/${data.tx}?cluster=devnet`;
        // Show the notification
        notificationBox.style.display = 'flex';
        solscanLink.style.display = 'flex'
        notificationBox.style.opacity = 1

        // Hide the notification after 5 seconds
        setTimeout(() => {
            // notificationBox.style.display = 'none';
        notificationBox.style.opacity = 0
            // solscanLink.style.display = 'none'
            notificationText.textContent = ''
            solscanLink.href = ''
        }, 5000);
    });

    // Death.
    socket.on('RIP', function(data) {
        // console.log(data, "this is data")
        // try {
        //     signAndSendTransaction(data.tx).then((val) => {
        //         console.log("Transaction sent!", val)
        //     });
        // } catch (error) {
        //     console.error("Error sending transaction:", error.message);
        // }
        stopGame()


        // document.getElementById('retryButton').style.opacity = 0;
        // document.getElementById('playAgain').style.opacity = 0;
    //  document.getElementById('diedMenuWrapper').style.maxHeight = '100%';
    // document.getElementById('diedMenuWrapper').style.opacity = 1;
    });

    socket.on('kick', function (reason) {
        global.gameStart = false;
        global.kicked = true;
        if (reason !== '') {
            render.drawErrorMessage('You were kicked for: ' + reason, graph, global.screen);
        }
        else {
            render.drawErrorMessage('You were kicked!', graph, global.screen);
        }
        socket.close();
    });

}

const isUnnamedCell = (name) => name.length < 1;

const getPosition = (entity, player, screen) => {
    return {
        x: entity.x - player.x + screen.width / 2,
        y: entity.y - player.y + screen.height / 2
    }
}

window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

window.cancelAnimFrame = (function (handle) {
    return window.cancelAnimationFrame ||
        window.mozCancelAnimationFrame;
})();

function animloop() {
    global.animLoopHandle = window.requestAnimFrame(animloop);
    gameLoop();
}

function gameLoop() {
    if (global.gameStart) {
        graph.fillStyle = global.backgroundColor;
        graph.fillRect(0, 0, global.screen.width, global.screen.height);

        render.drawGrid(global, player, global.screen, graph);
        foods.forEach(food => {
            let position = getPosition(food, player, global.screen);
            render.drawFood(position, food, graph);
        });
        priceFoods.forEach(priceFood => {
            let position = getPosition(priceFood, player, global.screen);
            render.drawFood(position, priceFood, graph);
        });
        fireFood.forEach(fireFood => {
            let position = getPosition(fireFood, player, global.screen);
            render.drawFireFood(position, fireFood, playerConfig, graph);
        });
        viruses.forEach(virus => {
            let position = getPosition(virus, player, global.screen);
            render.drawVirus(position, virus, graph);
        });


        let borders = { // Position of the borders on the screen
            left: global.screen.width / 2 - player.x,
            right: global.screen.width / 2 + global.game.width - player.x,
            top: global.screen.height / 2 - player.y,
            bottom: global.screen.height / 2 + global.game.height - player.y
        }
        if (global.borderDraw) {
            render.drawBorder(borders, graph);
        }

        var cellsToDraw = [];
        for (var i = 0; i < users.length; i++) {
            // Check if hue is a color string or a numeric hue value
            let color = typeof users[i].hue === 'string'
                ? users[i].hue
                : 'hsl(' + users[i].hue + ', 100%, 50%)';

            let borderColor = color


            for (var j = 0; j < users[i].cells.length; j++) {
                cellsToDraw.push({
                    color: color,
                    borderColor: borderColor,
                    mass: users[i].cells[j].mass,
                    worth: users[i].cells[j].worth || 0,
                    mint: users[i].cells[j].mint,
                    name: users[i].name,
                    radius: users[i].cells[j].radius,
                    x: users[i].cells[j].x - player.x + global.screen.width / 2,
                    y: users[i].cells[j].y - player.y + global.screen.height / 2
                });
            }
        }

        cellsToDraw.sort(function (obj1, obj2) {
            return obj1.mass - obj2.mass;
        });

        render.drawCells(cellsToDraw, playerConfig, global.toggleMassState, borders, graph);

        socket.emit('0', window.canvas.target); // playerSendTarget "Heartbeat".
    }
}

window.addEventListener('resize', resize);

function resize() {
    if (!socket) return;

    player.screenWidth = c.width = global.screen.width = global.playerType == 'player' ? window.innerWidth : global.game.width;
    player.screenHeight = c.height = global.screen.height = global.playerType == 'player' ? window.innerHeight : global.game.height;

    if (global.playerType == 'spectator') {
        player.x = global.game.width / 2;
        player.y = global.game.height / 2;
    }

    socket.emit('windowResized', { screenWidth: global.screen.width, screenHeight: global.screen.height });
}

// WEB3 Utility Functions

async function getGamePdaAccount(userPublicKey) {
    const [pda] = await solanaWeb3.PublicKey.findProgramAddress(
        [Buffer.from("user_pda"), new solanaWeb3.PublicKey(userPublicKey).toBuffer()],
        programId
    );

    console.log(pda.toBase58(), 'from gamepdaaccount')

    return pda.toBase58()
}

async function getPdaBalance(userPublicKey) {
    try {
        const [pda] = await solanaWeb3.PublicKey.findProgramAddress(
            [Buffer.from("user_pda"), new solanaWeb3.PublicKey(userPublicKey).toBuffer()],
            programId
        );

        const balanceLamports = await connection.getBalance(pda);
        const balanceSol = balanceLamports / LAMPORTS_PER_SOL;

        console.log(`PDA balance for user ${userPublicKey}: ${balanceSol} SOL`);
        return balanceSol;
    } catch (error) {
        console.error("Error fetching PDA balance:", error);
        return 0;
    }
}

function generateNewKeypair() {
    const keypair = new solanaWeb3.Keypair();
    localStorage.setItem('solanaPrivateKey', JSON.stringify(Array.from(keypair.secretKey)));
    return keypair.publicKey.toString();
}

async function sendSolTransaction() {
    const { Connection, PublicKey, Keypair, Transaction, SystemProgram, LAMPORTS_PER_SOL } = solanaWeb3;

    // Solana connection (use mainnet or testnet URL as appropriate)

    // Fetch the user's private key from localStorage
    const privateKeyArray = JSON.parse(localStorage.getItem('solanaPrivateKey'));
    const senderKeypair = Keypair.fromSecretKey(Uint8Array.from(privateKeyArray));
    const senderPublicKey = senderKeypair.publicKey;

    // Specify the recipient public key
    const recipientPublicKey = new PublicKey(RECIPIENT_WALLET);

    // Create the transaction to transfer SOL
    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: senderPublicKey,
            toPubkey: recipientPublicKey,
            lamports: SOL_AMOUNT * LAMPORTS_PER_SOL, // Convert SOL to lamports
        })
    );

    // Send the transaction
    const signature = await connection.sendTransaction(transaction, [senderKeypair]);
    console.log("Transaction signature:", signature);
}

async function fetchWalletBalance(wallet) {
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "tsT34DQrds1Hkgmn");

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const resp = await fetch(`https://api.shyft.to/sol/v1/wallet/balance?network=devnet&wallet=${wallet}`, requestOptions)
        const responseJson = await resp.json();
        return responseJson?.result?.balance || 0
    } catch (e) {
        console.error(e, "error");
        return 0;
    }
}

async function signAndSendTransaction(serializedTx) {
    const { Connection, Keypair, Transaction } = solanaWeb3;

    // Decode the Base64-encoded transaction string to Uint8Array
    const txBuffer = Uint8Array.from(atob(serializedTx), c => c.charCodeAt(0));
    const transaction = Transaction.from(txBuffer);

    // Fetch the user's private key from localStorage
    const privateKeyArray = JSON.parse(localStorage.getItem('solanaPrivateKey'));
    const senderKeypair = Keypair.fromSecretKey(Uint8Array.from(privateKeyArray));

    // Sign the transaction
    transaction.sign(senderKeypair);

    // Send the signed transaction
    const signature = await connection.sendRawTransaction(transaction.serialize());
    console.log("Transaction signature:", signature);
}

async function initializePDA(userPublicKey) {
    try {
        console.log("Initializing PDA for user", userPublicKey);

        const [pda, bump] = await solanaWeb3.PublicKey.findProgramAddress(
            [Buffer.from("user_pda"), new solanaWeb3.PublicKey(userPublicKey).toBuffer()],
            programId
        );

        console.log("Derived PDA:", pda.toBase58());

        // Determine the minimum balance for rent exemption
        const rentExemptBalance = await connection.getMinimumBalanceForRentExemption(8);
        console.log(`Required rent-exempt balance: ${rentExemptBalance} lamports`);

        const instructionData = Buffer.from([139, 157, 13, 41, 142, 174, 226, 214])
        // Prepare a transaction to call the `create_user_pda` instruction in the program
        const transaction = new solanaWeb3.Transaction().add(
            new solanaWeb3.TransactionInstruction({
                keys: [
                    { pubkey: pda, isSigner: false, isWritable: true },
                    { pubkey: new solanaWeb3.PublicKey(userPublicKey), isSigner: true, isWritable: true },
                    { pubkey: new solanaWeb3.PublicKey(global.pubKey), isSigner: true, isWritable: true },
                    { pubkey: solanaWeb3.SystemProgram.programId, isSigner: false, isWritable: false },
                ],
                programId: programId,
                data: instructionData, // No additional data needed for initialization
            })
        );

        // Fetch the latest blockhash for the transaction
        const latestBlockhash = await connection.getLatestBlockhash('finalized');
        console.log("Latest blockhash:", latestBlockhash.blockhash);
        transaction.recentBlockhash = latestBlockhash.blockhash;
        transaction.feePayer = new solanaWeb3.PublicKey(global.pubKey);

        // Check if fee payer and transaction are valid
        // console.log("Fee payer:", global.pubKey.toBase58());
        console.log("Transaction prepared for PDA initialization");

        // Serialize and send the transaction
        const serializedTransaction = transaction.serialize({
            requireAllSignatures: false,
            verifySignatures: true
        });

        const signature = await signAndSendTransaction(serializedTransaction.toString('base64'));

        console.log(`PDA initialized with transaction signature: ${signature}`);
        return pda;
    } catch (error) {
        console.error("Error initializing PDA:", error);
        throw error;
    }
}

async function ensurePDAInitialized(userPublicKey) {
    try {
        const [pda] = await solanaWeb3.PublicKey.findProgramAddress(
            [Buffer.from("user_pda"), new solanaWeb3.PublicKey(userPublicKey).toBuffer()],
            programId
        );

        // Check if the PDA is initialized by attempting to fetch account info
        const accountInfo = await connection.getAccountInfo(pda);
        if (accountInfo) {
            console.log(`PDA is already initialized for user ${userPublicKey}`);
            return pda; // Return the existing PDA address
        } else {
            console.log(`PDA not initialized for user ${userPublicKey}, initializing now...`);

            // Call the initializePDA function to create the PDA
            await initializePDA(userPublicKey);

            console.log(`PDA initialized successfully for user ${userPublicKey}`);
            return pda;
        }
    } catch (error) {
        console.error("Error ensuring PDA is initialized:", error);
        throw error;
    }
}

async function fetchSolBalanceWithInfo(address) {
    const tokenPrice = await fetchTokenPrice('So11111111111111111111111111111111111111112');
    const nativeBalance = await fetchWalletBalance(address);
    const solBalance = {
        address: "So11111111111111111111111111111111111111112",
        balance: nativeBalance || 0,
        info: { symbol: "SOL" },
        priceUSD: tokenPrice || 0,
        valueUSD: (nativeBalance * tokenPrice) || 0,
        name: "SOL"
    }
    return solBalance
}

async function getSOLBalance(address) {
    try {
        const publicKey = new solanaWeb3.PublicKey(address);
        const balanceLamports = await connection.getBalance(publicKey);
        const balanceSol = balanceLamports / LAMPORTS_PER_SOL;

        console.log(`PDA balance for user ${address}: ${balanceSol} SOL`);
        return balanceSol;
    } catch (error) {
        console.error("Error fetching PDA balance:", error);
        return 0;
    }
}

async function fetchTokenPrice(token) {
    try {
        const resp = await fetch(`https://api.jup.ag/price/v2?ids=${token}`);
        const responseJson = await resp.json();
        if (!(token in responseJson?.data)) {
            return 0;
        }
        return responseJson?.data[token]?.price;
    } catch (e) {
        console.error(e, "error");
        return 0;
    }
}

async function sendDevSolToWallet(pubKey, gameCost) {
    try {
        console.log("topping up user wallet")
        const { Connection, PublicKey, Keypair, Transaction, SystemProgram, LAMPORTS_PER_SOL } = solanaWeb3;

        const MIN_BALANCE_SOL = gameCost;
        const senderPublicKey = topUpWallet.publicKey;
        console.log("sender topup Wallet: ", senderPublicKey.toBase58())

        const recipientPublicKey = new PublicKey(pubKey);

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: senderPublicKey,
                toPubkey: recipientPublicKey,
                lamports: MIN_BALANCE_SOL * LAMPORTS_PER_SOL, // Convert SOL to lamports
            })
        );

    // Send the transaction
    const signature = await connection.sendTransaction(transaction, [topUpWallet]);
    console.log("Transaction signature:", signature);
    } catch (error) {
        throw new Error(error)
    }
}

async function ensureEnoughBalance(from, gameCost) {
    const MIN_BALANCE_SOL = gameCost;
    console.log(`MINIMYM TO PLAY: `, MIN_BALANCE_SOL)
    const LAMPORTS_PER_SOL = 1_000_000_000;

    try {
        // Calculate the minimum required balance in lamports
        const minBalanceLamports = MIN_BALANCE_SOL * LAMPORTS_PER_SOL;

        // Fetch rent-exempt balance for the PDA
        const rentExemptBalance = await connection.getMinimumBalanceForRentExemption(8); // Adjust size as needed
        const requiredBalance = minBalanceLamports + rentExemptBalance;

        // Get the current SOL balance of the PDA in lamports
        const currentBalance = await getSOLBalance(from) * LAMPORTS_PER_SOL

        console.log(`Current balance of PDA: ${currentBalance} lamports`);
        console.log(`Required balance (0.05 SOL + rent exempt): ${requiredBalance} lamports`);

        // If the PDA has enough balance, we are done
        if (currentBalance >= requiredBalance) {
            console.log("PDA has sufficient balance. No top-up needed.");
            return { success: 1, amountNeeded: 0 }
        }

        // Calculate the amount needed to reach the required balance
        const amountNeeded = requiredBalance - currentBalance;
        console.log(`PDA needs additional ${amountNeeded} lamports. Initiating transfer...`);
        return { success: 0, amountNeeded: amountNeeded / LAMPORTS_PER_SOL }
    } catch (error) {
        console.error("Error ensuring sufficient PDA balance:", error);
        throw new Error(error)
    }
}
