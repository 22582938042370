class Particle {
  constructor(canvas, index, total) {
    this.canvas = canvas;
    this.reset(index, total);
    this.angle = Math.random() * Math.PI * 2;
    this.speed = 0.4 + Math.random() * 0.5;
    this.pulsePhase = Math.random() * Math.PI * 2;
    this.size = 4; // Fixed small size for all particles
  }

  reset(index, total) {
    const cols = Math.ceil(Math.sqrt(total));
    const rows = Math.ceil(total / cols);
    const cellWidth = this.canvas.width / cols;
    const cellHeight = this.canvas.height / rows;

    const col = index % cols;
    const row = Math.floor(index / cols);

    this.x = (col * cellWidth) + (Math.random() * cellWidth * 0.8 + cellWidth * 0.1);
    this.y = (row * cellHeight) + (Math.random() * cellHeight * 0.8 + cellHeight * 0.1);

    this.originalX = this.x;
    this.originalY = this.y;
  }

  update(mouse) {
    const time = Date.now() * 0.001;
    const radius = 30;
    this.x = this.originalX + Math.cos(this.angle + time * this.speed) * radius;
    this.y = this.originalY + Math.sin(this.angle + time * this.speed) * radius;

    if (mouse.x !== null && mouse.y !== null) {
      const dx = mouse.x - this.x;
      const dy = mouse.y - this.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const maxDistance = 150;

      if (distance < maxDistance) {
        const force = (1 - distance / maxDistance) * 0.08;
        const angle = Math.atan2(dy, dx);
        const swirl = mouse.moving ? 1 : -1;
        this.x += Math.cos(angle + Math.PI/2 * swirl) * force * 15;
        this.y += Math.sin(angle + Math.PI/2 * swirl) * force * 15;
      }
    }

    const buffer = this.size * 2;
    if (this.x < -buffer) this.x = this.canvas.width + buffer;
    if (this.x > this.canvas.width + buffer) this.x = -buffer;
    if (this.y < -buffer) this.y = this.canvas.height + buffer;
    if (this.y > this.canvas.height + buffer) this.y = -buffer;
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.85)';
    ctx.lineWidth = 1;
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(this.x, this.y, this.size * 0.5, 0, Math.PI * 2);
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.3)';
    ctx.lineWidth = 0.5;
    ctx.stroke();
  }
}

class ParticleSystem {
    constructor(canvasId = 'particleCanvas') {
        // Automatically find the canvas if a string ID is provided
        this.canvas = document.getElementById(canvasId);
        if (!this.canvas) {
            throw new Error(`Canvas element with ID "${canvasId}" not found.`);
        }
        this.ctx = this.canvas.getContext('2d');
        this.particles = Array.from({ length: 200 }, (_, i) => new Particle(this.canvas, i, 200));
        this.mouse = { x: null, y: null, moving: false, lastX: null, lastY: null, speed: 0 };
        this.running = true; // Set running to true initially
        this.animationFrameId = null; // Store the animation frame ID for stopping

        this.handleResize(); // Initial resize to set canvas dimensions
        this.setupMouseTracking();
        this.resize();
        window.addEventListener('resize', this.handleResize.bind(this)); // Bind to class instance
        this.animate(); // Start animation automatically
        this.start()
    }

    handleResize() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.particles.forEach((particle, i) => particle.reset(i, this.particles.length));
    }

    setupMouseTracking() {
        let mouseTimeout;
        let lastMouseX = 0;
        let lastMouseY = 0;
        let lastMoveTime = Date.now();

        this.canvas.addEventListener('mousemove', (e) => {
            const rect = this.canvas.getBoundingClientRect();
            const newX = e.clientX - rect.left;
            const newY = e.clientY - rect.top;

            const dx = newX - lastMouseX;
            const dy = newY - lastMouseY;
            const dt = (Date.now() - lastMoveTime) / 1000;
            this.mouse.speed = Math.sqrt(dx * dx + dy * dy) / dt;

            lastMouseX = newX;
            lastMouseY = newY;
            lastMoveTime = Date.now();

            this.mouse.moving = true;
            clearTimeout(mouseTimeout);

            mouseTimeout = setTimeout(() => {
                this.mouse.moving = false;
                this.mouse.speed = 0;
            }, 50);

            this.mouse.x = newX;
            this.mouse.y = newY;
        });

        this.canvas.addEventListener('mouseleave', () => {
            this.mouse.x = null;
            this.mouse.y = null;
            this.mouse.moving = false;
            this.mouse.speed = 0;
        });

        this.canvas.addEventListener('touchmove', (e) => {
            e.preventDefault();
            const rect = this.canvas.getBoundingClientRect();
            const touch = e.touches[0];
            this.mouse.x = touch.clientX - rect.left;
            this.mouse.y = touch.clientY - rect.top;
            this.mouse.moving = true;
        });

        this.canvas.addEventListener('touchend', () => {
            this.mouse.x = null;
            this.mouse.y = null;
            this.mouse.moving = false;
            this.mouse.speed = 0;
        });
    }

    drawGrid() {
        const gridSize = 40;
        this.ctx.strokeStyle = 'rgba(255, 255, 255, 0.03)';
        this.ctx.lineWidth = 1;

        for (let x = 0; x <= this.canvas.width; x += gridSize) {
            this.ctx.beginPath();
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, this.canvas.height);
            this.ctx.stroke();
        }

        for (let y = 0; y <= this.canvas.height; y += gridSize) {
            this.ctx.beginPath();
            this.ctx.moveTo(0, y);
            this.ctx.lineTo(this.canvas.width, y);
            this.ctx.stroke();
        }
    }

    animate() {
        if (!this.running) return; // Stop the animation if not running

        this.ctx.fillStyle = 'rgba(10, 10, 10, 0.4)';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        this.drawGrid();

        this.particles.forEach((particle) => {
            particle.update(this.mouse);
            particle.draw(this.ctx);
        });

        this.animationFrameId = requestAnimationFrame(() => this.animate());
    }

    start() {
        if (!this.running) {
            this.running = true;
            this.animate();
        }
    }

    stop() {
        this.running = false;
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }
    }

    resize() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.particles.forEach((particle, i) =>
            particle.reset(i, this.particles.length)
        );
    }
}

module.exports = ParticleSystem;
